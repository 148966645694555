import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

const baseUrl = process.env.REACT_APP_BASE_URL
function DynamicTable(props) {
  let {studentsList, rosterData, totalLearningTargets} = props
  let totalColoums = props.learningTargets

  const [column, setColumns] = useState(totalColoums)
  const [students, setstudentsList] = useState([])
  const [isReversed, setIsReversed] = useState(false)
  const [count, setCount] = useState(1)
  // const [rosterId, setRosterId] = useState()
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    setstudentsList(studentsList)
    setColumns(totalColoums)
  }, [props])

  const getColorOfLearningTarget = (d, Name) => {
    for (let i = 0; i <= students.length - 1; i++) {
      if (students[i].id === Name.id) {
        console.log(students[i])
        for (let j = 0; j <= students[i].allInProgress.length - 1; j++) {
          if (students[i].allInProgress[j].learningTarget == d.learningTarget) {
            if (
              students[i].allInProgress[j].imported === true &&
              students[i].allInProgress[j].status === 'P'
            ) {
              return true
            } else {
              return false
            }
          }
        }
      }
    }
  }

  const getColorOfLearningTarget2 = (d, sId) => {
    console.log(d, sId, students)
    for (let i = 0; i <= students.length - 1; i++) {
      if (students[i].id === sId) {
        console.log(students[i])
        for (let j = 0; j <= students[i].allInProgress.length - 1; j++) {
          if (students[i].allInProgress[j].learningTarget == d) {
            if (
              students[i].allInProgress[j].imported === true &&
              students[i].allInProgress[j].status === 'P'
            ) {
              return true
            } else {
              return false
            }
          }
        }
      }
    }
  }

  const handleHeaderClick = () => {
    let sortedStudents = students.sort((a, b) =>
      a.lastName.localeCompare(b.lastName),
    )
    setstudentsList([...sortedStudents])
    setIsReversed(true)
  }

  const handleHeaderClick2 = () => {
    let sortedStudents = students.sort((a, b) =>
      b.lastName.localeCompare(a.lastName),
    )
    setstudentsList([...sortedStudents])
    setIsReversed(true)
  }

  const handleLearnerYearClick = () => {
    let sortedStudents = students.sort((a, b) => a.year - b.year)
    setstudentsList([...sortedStudents])
    setIsReversed(true)
  }

  const handleLearnerYearClick2 = () => {
    let sortedStudents = students.sort((a, b) => b.year - a.year)
    setstudentsList([...sortedStudents])
    setIsReversed(true)
  }

  useEffect(() => {
    if (isReversed) {
      setIsReversed(false)
      setCount(count + 1)
    }
  }, [isReversed])

  const ThData = () => {
    return (
      <>
        {totalLearningTargets.length ? (
          <>
            <th key="fullName"></th>
            {totalLearningTargets.map((data, key) => {
              return (
                <th key={data.progression.learningTarget}>
                  {data.progression.learningTarget}
                </th>
              )
            })}
          </>
        ) : (
          <>
            {count % 2 === 0 ? (
              <>
                {rosterData.advisory && (
                  <th width="300">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                      style={{fontSize: '14px', cursor: 'pointer'}}
                      onClick={() => handleLearnerYearClick()}>
                      Learner Year ⥮
                    </span>
                  </th>
                )}
                <th key="fullName">
                  &nbsp;&nbsp;
                  <span
                    style={{fontSize: '14px', cursor: 'pointer'}}
                    onClick={() => handleHeaderClick()}>
                    Student Name ⥮
                  </span>
                </th>
              </>
            ) : (
              <>
                {rosterData.advisory && (
                  <th width="300">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                      style={{fontSize: '14px', cursor: 'pointer'}}
                      onClick={() => handleLearnerYearClick2()}>
                      Learner Year ⥯
                    </span>
                  </th>
                )}
                <th key="fullName">
                  &nbsp;&nbsp;
                  <span
                    style={{fontSize: '14px', cursor: 'pointer'}}
                    onClick={() => handleHeaderClick2()}>
                    Student Name ⥯
                  </span>
                </th>
              </>
            )}
            {column.map((data, key) => {
              return (
                <th
                  className={data.seprater ? 'table-th-bx' : ''}
                  key={data.learningTarget}>
                  {data.learningTarget}
                </th>
              )
            })}
          </>
        )}
      </>
    )
  }

  const tdData = () => {
    return students.map(student => {
      let completedTarget = student.completedTarget.split(',')
      if (completedTarget.length > 0) {
      }
      const myData = learnTarget => {
        var found = null
        let lastInprogressTarget = -1
        if (student.allInProgress) {
          found = student.allInProgress.find(
            e => e.learningTarget == learnTarget,
          )
        }
        if (found && found.backFilled === true && found.status === 'P') {
          return 'B'
        } else if (
          found &&
          (found.backFilled === null || found.backFilled === false) &&
          found.status === 'P'
        ) {
          return 'P'
        } else if (found && found.backFilled === null) {
          return found.status
        } else if (
          found &&
          found.backFilled === false &&
          found.status === 'IP'
        ) {
          return found.status
        } else {
          return ''
        }
      }

      return (
        <tr
          style={{
            borderBottom: '1px solid #e8e8e8',
            color: '333',
            fontSize: '13px',
          }}>
          {rosterData.advisory ? (
            <>
              <td> {student.year}</td>
              <td>
                <Link
                  to={{
                    pathname: '/student-timeline',
                    state: {
                      studentId: student.id,
                      studentName: student.firstName + ' ' + student.lastName,
                    },
                  }}>
                  {student.firstName} {student.lastName}{' '}
                </Link>
              </td>
            </>
          ) : (
            <td>
              {student.firstName} {student.lastName}{' '}
            </td>
          )}
          {totalLearningTargets.length > 0
            ? totalLearningTargets.length > 0 &&
              [...Array(totalLearningTargets.length)].map((data, key) => {
                console.log(totalLearningTargets[key].progression)
                return (
                  <>
                    <td className="unselectable" style={{textAlign: 'left'}}>
                      <Link
                        className="link-text "
                        style={{
                          backgroundColor:
                            myData(
                              totalLearningTargets[key].progression
                                .learningTarget,
                            ) === 'P' ||
                            myData(
                              totalLearningTargets[key].progression
                                .learningTarget,
                            ) === 'B'
                              ? getColorOfLearningTarget2(
                                  totalLearningTargets[key].progression
                                    .learningTarget,
                                  student.id,
                                )
                                ? '#056608 '
                                : '#28a745'
                              : '#868686',
                          color: 'white',
                        }}
                        to={{
                          pathname: '/proficiency',
                          state: {
                            studentId: student.id,
                            studentName:
                              student.firstName + ' ' + student.lastName,
                            subject: rosterData?.subject
                              ? rosterData?.subject
                              : props?.names?.subject,
                            phase: rosterData?.phase
                              ? rosterData.phase
                              : props?.names?.phase,
                            categoryId: rosterData?.categoryId
                              ? rosterData.categoryId
                              : props?.names?.categoryID,
                            category: rosterData?.category
                              ? rosterData.category
                              : props?.names?.category,
                            rosterId: rosterData.id ? rosterData.id : 0,
                            learningTarget:
                              totalLearningTargets[key].progression
                                .learningTarget,
                            status: myData(
                              totalLearningTargets[key].progression
                                .learningTarget,
                            ),
                            isAdvisory: props.isAdvisory,
                          },
                        }}>
                        {myData(
                          totalLearningTargets[key].progression.learningTarget,
                        )}
                      </Link>
                    </td>
                  </>
                )
              })
            : column?.map((data, key) => {
                console.log(data)
                let myTargetStatus = myData(data.learningTarget)
                return (
                  <>
                    <td
                      className={data.seprater ? 'table-td-bx' : ''}
                      style={{textAlign: 'left'}}>
                      <Link
                        className={
                          data.id && data.id != -1
                            ? 'link-text'
                            : 'link-text diable-link'
                        }
                        style={{
                          backgroundColor:
                            myTargetStatus === 'P' || myTargetStatus === 'B'
                              ? getColorOfLearningTarget(data, student)
                                ? '#056608 '
                                : '#28a745'
                              : '#868686',
                          color: 'white',
                        }}
                        to={{
                          pathname: '/proficiency',
                          state: {
                            studentId: student.id,
                            studentName:
                              student.firstName + ' ' + student.lastName,
                            subject: rosterData.subject,
                            phase: rosterData.phase,
                            categoryId: rosterData.categoryId,
                            category: rosterData.category,
                            learningTarget: data.learningTarget,
                            status: myTargetStatus,
                            rosterId: rosterData.id,
                            modifiedOn: '',
                            modifiedBy: '',
                          },
                        }}>
                        {myTargetStatus}
                      </Link>
                    </td>
                  </>
                )
              })}
        </tr>
      )
    })
  }

  return (
    <div
      className="grand-total-bx "
      style={{textAlign: 'left', overflowX: 'auto', overflowY: 'hidden'}}>
      <table
        style={{
          borderCollapse: 'collapse',
          borderSpacing: '0',
          width: '100%',
          border: '1px solid #ddd',
        }}>
        <thead>
          <tr>{ThData()}</tr>
        </thead>
        <tbody
          style={{
            borderBottom: '1px solid #e8e8e8',
            color: '333',
            fontSize: '13px',
          }}>
          {tdData()}
        </tbody>
      </table>
    </div>
  )
}
export default DynamicTable
