// export default customer
import Layouts from '../../components/Layouts'
import React, {useState, useEffect} from 'react'
import isJwtTokenExpired from 'jwt-check-expiry'
import AddTutorials from './AddTutorials'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import EditTutorials from './EditTutorials'
import Messages from '../../Message/Message'
import $ from 'jquery'
import {EditOutlined, CloseOutlined} from '@ant-design/icons'
import {
  Col,
  Row,
  Input,
  Button,
  Modal,
  Table,
  Tooltip,
  message,
  Popconfirm,
} from 'antd'
const baseUrl = process.env.REACT_APP_BASE_URL

const ManageTutorials = () => {
  const {Search} = Input
  const history = useHistory()
  const [tutorialLIst, setTutorialList] = useState([])
  const [idForVisibleModel, setIdForVisibleModel] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [filterTable, setFilterTable] = useState([])
  var myAccount = useSelector(state => state.myAccount.value)
  var user = JSON.parse(localStorage.getItem('user'))
  const schoolID = user.schoolId
  let headers = {'Content-Type': 'application/json'}
  const token = myAccount.token ? myAccount.token : user.token
  if (user) {
    var isExpired = isJwtTokenExpired(user.token)

    if (isExpired) {
      message.error(`${Messages.unHandledErrorMsg}`)
      history.replace({pathname: '/', state: {isActive: true}})
    }
  } else {
    message.error(`${Messages.unHandledErrorMsg}`)
    history.replace({pathname: '/', state: {isActive: true}})
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const fetchTutorials = () => {
    fetch(`${baseUrl}/Tutorials/GetAll?schoolId=${parseInt(schoolID)}`, {
      headers,
    })
      .then(res => res.json())
      .then(data => {
        console.log(data.data)
        data.data && setTutorialList([...data.data])
      })
  }

  useEffect(() => {
    fetchTutorials()
    $('.anticon.anticon-menu-fold.trigger.layout-trigger.header-toggle').click()
    const isMobile = window.innerWidth <= 767
    if (isMobile) {
      $(
        '.anticon.anticon-menu-fold.trigger.layout-trigger.header-toggle',
      ).click()
    }
  }, [])

  useEffect(() => {
    setFilterTable([...tutorialLIst])
  }, [tutorialLIst])

  const updateTutorials = () => {
    fetchTutorials()
  }

  const showModal = id => {
    setIsModalVisible(true)
    setIdForVisibleModel(id)
  }

  const deleteTutorial = async id => {
    console.log(id)
    try {
      await fetch(`${baseUrl}/Tutorials/Delete?tutorialId=${id}`, {
        method: 'POST',
        headers,
      })
      fetchTutorials()
    } catch (e) {
      console.log(e)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSearch = value => {
    const searchRes = tutorialLIst.filter(o =>
      Object.keys(o).some(k =>
        String(o[k]).toLowerCase().includes(value.toLowerCase()),
      ),
    )
    setFilterTable([...searchRes])
  }

  const columns = [
    {
      title: 'Tutorial Name',
      dataIndex: 'name',
      key: 'tutorialName',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, data) => <h3>{data.name}</h3>,
    },
    {
      title: 'Tutorial Video',
      dataIndex: 'url',
      key: 'tutorialVideo',
      sorter: (a, b) => a.url.localeCompare(b.url),
      render: (text, data) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {data.url}
        </a>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (id, data) => (
        <>
          <Tooltip title="Edit Tutorial">
            <Button type="primary" onClick={() => showModal(id)}>
              <EditOutlined />
            </Button>
          </Tooltip>
          <Popconfirm
            title="Are you certain you wish to delete this Report ?"
            onConfirm={() => deleteTutorial(id)}>
            <Tooltip title="Delete Tutorial">
              <Button
                type="primary"
                style={{backgroundColor: 'red', borderColor: 'red'}}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
          <Modal
            title="Update Tutorial Details"
            footer={null}
            visible={idForVisibleModel === id ? isModalVisible : false}
            onOk={handleOk}
            onCancel={handleCancel}>
            <EditTutorials
              key={id}
              data={data}
              handleOk={handleOk}
              updateTutorials={updateTutorials}
            />
          </Modal>
        </>
      ),
    },
  ]

  const columnsForStudent = [
    {
      title: 'Tutorial Name',
      dataIndex: 'name',
      key: 'tutorialName',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, data) => <h3>{data.name}</h3>,
    },
    {
      title: 'Tutorial Video',
      dataIndex: 'url',
      key: 'tutorialVideo',
      sorter: (a, b) => a.url.localeCompare(b.url),
      render: (text, data) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {data.url}
        </a>
      ),
    },
  ]

  const columnsForAdmin_SuperAdmin = [
    {
      title: 'Tutorial Name',
      dataIndex: 'name',
      key: 'tutorialName',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, data) => <h3>{data.name}</h3>,
    },
    {
      title: 'Tutorial Video',
      dataIndex: 'url',
      key: 'tutorialVideo',
      sorter: (a, b) => a.url.localeCompare(b.url),
      render: (text, data) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {data.url}
        </a>
      ),
    },
    {
      title: 'Accessibility',
      dataIndex: 'url',
      key: 'tutorialVideo',
      render: (text, data) => <h3>{data.canBeAccessedBy}</h3>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (id, data) => (
        <>
          <Tooltip title="Edit Tutorial">
            <Button type="primary" onClick={() => showModal(id)}>
              <EditOutlined />
            </Button>
          </Tooltip>
          <Popconfirm
            title="Are you certain you wish to delete this Tutorial ?"
            onConfirm={() => deleteTutorial(id)}>
            <Tooltip title="Delete Tutorial">
              <Button
                type="primary"
                style={{backgroundColor: 'red', borderColor: 'red'}}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
          <Modal
            title="Update Tutorial Details"
            footer={null}
            visible={idForVisibleModel === id ? isModalVisible : false}
            onOk={handleOk}
            onCancel={handleCancel}>
            <EditTutorials
              key={id}
              data={data}
              handleOk={handleOk}
              updateTutorials={updateTutorials}
            />
          </Modal>
        </>
      ),
    },
  ]

  return (
    <>
      <Layouts title="assets" className="dashboard">
        <div className="dash-bg-white bg-blue-light">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={7} xl={5}>
              <div className="section-top-heading">
                <h3
                  style={{
                    color: '#0C1362',
                    fontWeight: '600',
                    fontSize: '20px',
                  }}>
                  {' '}
                  Tutorials{' '}
                </h3>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={19}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={17} lg={20} xl={20}>
                  <div className="section-top-heading">
                    <Search
                      placeholder="Input search text"
                      onChange={e => onSearch(e.target.value)}
                      onSearch={onSearch}
                    />
                  </div>
                </Col>
                {user.role.toLowerCase() !== 'student' && (
                  <Col xs={24} sm={24} md={7} lg={4} xl={4}>
                    <AddTutorials updateTutorials={updateTutorials} />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="table-grid-bx">
          <Table
            columns={
              user.role === 'Student'
                ? columnsForStudent
                : user.role === 'SuperAdmin' || user.role === 'Admin'
                ? columnsForAdmin_SuperAdmin
                : columns
            }
            dataSource={filterTable}
            rowKey={obj => obj.id}
          />
        </div>
      </Layouts>
    </>
  )
}

export default ManageTutorials
