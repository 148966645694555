
// export default customer
import Layouts from '../../components/Layouts'
import React, { useState , useEffect} from 'react'
import isJwtTokenExpired from 'jwt-check-expiry';
import AddTeam from './AddTeam.jsx'
import Messages from '../../Message/Message';
import {useSelector , useDispatch } from 'react-redux';
import {updateMyAccount} from '../../Slicers/myAccountSlice'
import { useHistory } from "react-router-dom";
import $ from 'jquery'
import EditTeam from './EditTeam'
import {
  EditOutlined,
} from '@ant-design/icons'
import {
  Form,
  Col,
  Select,
  Row,
  Input,
  Tooltip,
  Button,
  Modal,
  Popconfirm,
  Table, Tag, Checkbox,message
} from 'antd'
import ActiveData from '../../helpers/ActiveData';
const baseUrl = process.env.REACT_APP_BASE_URL

const  ManageSubject = () => {
  const { Search } = Input;
  const history = useHistory()
  const [allDataLIst, setallDataList] = useState([]);
  const [activate ,setActivate] =useState(true)
const [teamList , setTeamList] = useState([]);
const [idForVisibleModel , setIdForVisibleModel] = useState(0);
const [isModalVisible, setIsModalVisible] = useState(false);
const [filterTable , setFilterTable] = useState([]);
var myAccount = useSelector((state)=> state.myAccount.value)
var subject = useSelector((state)=> state.subject.value)
  const dispatch = useDispatch();
  var user = JSON.parse(localStorage.getItem('user')) ;
  const schoolID = user.schoolId;
  let headers = {"Content-Type": "application/json"};
  const token = myAccount.token ? myAccount.token : user.token;
  //console.log("token" ,token )
  if(user){
    var isExpired = isJwtTokenExpired(user.token)
   
    if(isExpired){
    
     message.error(`${Messages.unHandledErrorMsg}`)
      history.replace({ pathname: '/', state: { isActive: true } })
    }
  }else{
    
   message.error(`${Messages.unHandledErrorMsg}`)
    history.replace({ pathname: '/', state: { isActive: true } })
  }
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
const fetchTeams = ()=>{
  fetch(`${baseUrl}/Teams/GetAll?schoolId=${parseInt(schoolID)}` , {headers,})
  .then((res) => res.json())
  .then((data) => {
    setallDataList(data.data)
     !myAccount.token &&  dispatch(updateMyAccount(user))
  })
} 


useEffect(()=>{
  fetchTeams();
     const isMobile = window.innerWidth <= 767 // Adjust the width threshold as needed
   if (isMobile) {
     $(
       '.anticon.anticon-menu-fold.trigger.layout-trigger.header-toggle',
     ).click()
   }
}, [])


useEffect(()=>{
  setFilterTable([...teamList]);
}, [teamList])

useEffect(()=>{
  let filterfor = 'teams' ;
  let activeDataOnly = ActiveData(allDataLIst , activate  ,filterfor)  ;
  setTeamList([...activeDataOnly])
}, [allDataLIst , activate])

const addNewTeamInList = (newTeam)=>{
  console.log("welcome Ad 1"  , newTeam);
  setTeamList([newTeam , ...teamList])
}

const updateTeamList =()=>{ 
  fetchTeams()
}

const showModal = (id) => {
  setIsModalVisible(true);
  setIdForVisibleModel(id)
  console.log("id" , id)
};

const handleOk = () => {
  setIsModalVisible(false);
};

const handleCancel = () => {
  setIsModalVisible(false);
};
 
const onSearch = value => {
  const searchRes = teamList.filter(o =>
    Object.keys(o).some(k =>
      String(o[k]).toLowerCase().includes(value.toLowerCase()),
    ),
  )
  console.log(searchRes)
  setFilterTable([...searchRes]);
}

  const columns = [
    {
      title: 'Team Name',
      dataIndex: 'teamName',
      key: 'teamName',
      render: text => <h3>{text}</h3>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => <h3>{text}</h3>,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'Status',
      render: status =>   <Tag color={status? "green" : "volcano"} key={status}>
                              {status ? "Active" : "Inactive" }
                          </Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'user',
      key: 'action',
      render:  (id, record) => (
    
        <>
        <Tooltip title='Edit Team'>
            <Button type="primary" onClick={()=>showModal(record.id)}>
              <EditOutlined />
            </Button>
            </Tooltip>
            <Modal title="Update Team Details" footer={null} visible={idForVisibleModel === record.id ? isModalVisible : false} onOk={handleOk} onCancel={handleCancel}>
                  <EditTeam updateTeamList={updateTeamList} teamDetails={record} />
            </Modal>
          
        </>
      ),
    
    },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: tags => (
    //     <>
    //       {tags.map(tag => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'loser') {
    //           color = 'volcano';
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => (
    //    <div>
    //    <a>Invite {record.subjectName}</a>
    //    <a>Delete</a>
    //    </div>
         
  
    //   ),
    // },
  ];

  return (
    <>
    
     <Layouts title="assets" className="dashboard">
      <div className="dash-bg-white bg-blue-light">
     
        
          <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={7} xl={5}>
              <div className="section-top-heading">
                <h3
                  style={{
                    color: '#0C1362',
                    fontWeight: '600',
                    fontSize: '20px',
                  }}>
                  {' '} Manage Teams{' '}
                </h3>
              </div>             
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={19}>
            <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={17} lg={20} xl={20}>
              <div className="section-top-heading">
               
                <Search placeholder="Input search text" onChange={e=>onSearch(e.target.value)} onSearch={onSearch} />
              </div>             
            </Col>
            <Col xs={24} sm={24} md={7} lg={4} xl={4}>
             <AddTeam addNewTeamInList={addNewTeamInList} />
            </Col> 
            </Row>         
            </Col>
          </Row>
        </div>
          <div className="text-end inactive-checkbox my-3">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
              <Checkbox className="input-check" onChange={()=>{setActivate(!activate)}}  name="isActive"     value={!(activate)}  checked={!(activate)} >Show Inactive</Checkbox>
              </Col>
            </Row>
          </div>
        <div className="table-grid-bx">
            <Table columns={columns} dataSource={filterTable} />
       </div>
    </Layouts>
    </>
  )
}

export default ManageSubject; 