import {Form, Input, Button, Checkbox, message} from 'antd'
import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Axios} from 'axios'
const baseUrl = process.env.REACT_APP_BASE_URL
const CreateTutorialsForm = props => {
  const {getFieldDecorator} = props.form
  const initialvalues = {
    name: '',
    url: '',
  }
  const [inputs, setInputs] = useState(initialvalues)
  const [isValidUrl, setIsValidUrl] = useState(true)
  const [selectedRole, setSelectedRole] = useState([])
  const [roles, setRoles] = useState(['Admin', 'Teacher', 'Student/Parent'])
  var myAccount = JSON.parse(localStorage.getItem('user'))
  const schoolID = myAccount.schoolId
  let headers = {'Content-Type': 'application/json'}
  const token = myAccount.token
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const isValidUrls = urlString => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i',
    )
    return !!urlPattern.test(urlString)
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.form.validateFields(err => {
      if (!err) {
        let data = inputs
        data.schoolId = schoolID
        data.canBeAccessedBy = selectedRole
        console.log(data)
        const requestMetadata = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
        fetch(`${baseUrl}/Tutorials/Save`, requestMetadata)
          .then(res => res.json())
          .then(data => {
            if (data.statusCode === 200) {
              message.success('Tutorial Is added successfully !!')
              props.updateTutorials(data.data)
              props.form.resetFields()
              setInputs({
                subjectName: '',
                isActive: false,
              })
              setSelectedRole([])
              props.handleOk()
            } else if (data.statusCode === 208) {
              message.warning(data.message)
            } else {
              message.info(data.message)
            }
          })
      }
    })
  }

  const handleChangeInputs = e => {
    let {name, value} = e.target
    if (name === 'roles') {
      console.log(value)
      if (value === 'All') {
        setSelectedRole(roles)
      } else {
        const isValuePresent = selectedRole.includes(value)
        if (isValuePresent) {
          const updatedArray = selectedRole.filter(item => item !== value)
          setSelectedRole(updatedArray)
        } else {
          setSelectedRole([...selectedRole, value])
        }
      }
    }
    if (name === 'url') {
      let isValid = isValidUrls(value)
      setIsValidUrl(isValid)
    }
    name === 'isActive' && (value = !value)
    setInputs(prevData => ({...prevData, [name]: value}))
  }

  return (
    <Form
      name="basic"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      onSubmit={handleSubmit}
      autoComplete="off">
      <Form.Item label="Name" name="tutorialname">
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: 'Please input  tutorial name!',
            },
          ],
        })(
          <Input
            name="name"
            setFieldsValue={inputs.name}
            onChange={handleChangeInputs}
          />,
        )}
      </Form.Item>

      <Form.Item label="Tutorial Video Url" name="url">
        {getFieldDecorator('url', {
          rules: [
            {
              required: true,
              message: 'Please input tutorial video url!',
            },
          ],
        })(
          <Input
            name="url"
            setFieldsValue={inputs.url}
            onChange={handleChangeInputs}
          />,
        )}
      </Form.Item>
      {isValidUrl ? (
        ''
      ) : (
        <p style={{textAlign: 'center', color: '#f5222d', marginLeft: '50px'}}>
          Please add a valid url format!
        </p>
      )}

      <Form.Item label="Roles" name="Roles">
        {getFieldDecorator('Roles', {
          rules: [
            {
              required: true,
              message: 'Please add Roles!',
            },
          ],
          roles,
        })(
          <div id="dropdown-container">
            <select
              id="multiple-checkboxes"
              name="roles"
              multiple={true}
              value={selectedRole}
              onChange={handleChangeInputs}>
              <option value="All">All</option>
              {roles.map(role => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>,
        )}
      </Form.Item>

      <Form.Item wrapperCol={{offset: 8, span: 16}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create()(CreateTutorialsForm)
