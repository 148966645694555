import * as React from 'react'
import {UserOutlined} from '@ant-design/icons'
import {deleteMyAccount} from '../Slicers/myAccountSlice'
import {connect} from 'react-redux'

import {Menu, Input, Avatar} from 'antd'

const SubMenu = Menu.SubMenu

const Search = Input.Search
const baseUrl = process.env.REACT_APP_BASE_URL
var userData = JSON.parse(localStorage.getItem('user'))

class HeaderDiv extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 'search',
      rtl: true,
      schoolData: JSON.parse(localStorage.getItem('schoolData')),
    }
    this.sidebarToggle = this.sidebarToggle.bind(this)
  }

  sidebarToggle() {
    this.setState({
      rtl: !this.state.rtl,
    })
    var body = document.body
    body.classList.toggle('rtl')
  }
  trgLogout = () => {
    //console.log("1")
    localStorage.clear()
    this.props.deleteMyAccount()
    window.location.href = '/'
  }
  componentDidMount(prevProps, prevState, snapshot) {
    // if (this.props.id !== prevProps.id) {
    var myAccount = JSON.parse(localStorage.getItem('user'))
    let headers = {'Content-Type': 'application/json'}
    const token = myAccount.token
    // console.log('token', token)
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    let schoolID = myAccount.schoolId
    if (schoolID != this.state.schoolData?.schoolID) {
      fetch(`${baseUrl}/Schools/Get?id=${parseInt(schoolID)}`, {headers})
        .then(res => res.json())
        .then(data => {
          let schoolData = {
            schoolID: data.data.id,
            logo: data.data.logo,
            schoolName: data.data.schoolName,
            studentYears: data.data.studentYears,
          }
          this.setState({
            schoolData: schoolData,
          })
          localStorage.setItem('schoolData', JSON.stringify(schoolData))
        })
    }
  }

  handleClick = e => {
    console.log('click ', e)
    this.setState({
      current: e.key,
    })
  }

  render() {
    const classBox = `primaryBg box`
    return (
      <Menu
        mode="horizontal"
        theme="dark"
        className="d-flex align-items-center custom-navigation">
        <Menu.Item key="brand-logo" className="brand-logo">
          {this.state.logoIMg === '' ? (
            ''
          ) : (
            <img
              src={`data:image/png;base64,${
                this.state.schoolData ? this.state.schoolData.logo : ''
              }`}
              alt="Logo"
            />
          )}
        </Menu.Item>

        <SubMenu
          key="profile"
          title={
            <span>
              <div className=" avatar-profile">
                <Avatar size={32} icon={<UserOutlined />} />
                <span
                  className="header-username"
                  style={{fontSize: '12px', fontWeight: '500', height: '28px'}}>
                  {' '}
                  {userData && userData.name}
                </span>
              </div>
            </span>
          }
          className="avatar-round auto">
          <Menu.Item key="logout">
            <div
              onClick={() => {
                this.trgLogout()
              }}>
              Logout
            </div>
          </Menu.Item>
        </SubMenu>
      </Menu>
    )
  }
}

export default connect(null, {deleteMyAccount})(HeaderDiv)
