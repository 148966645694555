import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Messages from '../../../Message/Message'
import {DatePicker} from 'antd'
import {Form, Select, Input, Button, message} from 'antd'
const baseUrl = process.env.REACT_APP_BASE_URL
var user = JSON.parse(localStorage.getItem('user'))

function AddBulkEntry(props) {
  const initialValues = {
    categoryId: props.rosterData.categoryId,
    learningTarget: 0,
    proficiency: '',
    date: '',
    rosterId: props.rosterData.id,
    teacherId: 0,
  }
  const {TextArea} = Input
  const {getFieldDecorator} = props.form
  const {Option} = Select
  const autoclearOn = false
  const history = useHistory()
  const [inputs, setInputs] = useState(initialValues)
  const [teachersList, setTeachersList] = useState([])
  const [learningTargetList, setLearningTargetList] = useState([])
  const [proficiencyList, setProficiencyList] = useState([
    'P',
    'IP',
    'Reset to default',
  ])
  let headers = {'Content-Type': 'application/json'}
  const schoolID = user.schoolId
  const token = user.token
  console.log('token', token)
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  useEffect(() => {
    console.log(props.learningTargets)
    props.learningTargets.sort((a, b) => a.learningTarget - b.learningTarget)
    setLearningTargetList(props.learningTargets)
    ;(user.role.toLowerCase() === 'admin' ||
      user.role.toLowerCase() === 'superadmin') &&
      fetch(`${baseUrl}/Users/GetAll?schoolId=${schoolID}`, {headers})
        .then(res => res.json())
        .then(data => {
          console.log(data)
          let users = data.data
          let techers = users.filter(
            obj =>
              obj.roleName.toLowerCase() === 'teacher' ||
              (user.role.toLowerCase() === 'admin' &&
                user?.userId === obj.id &&
                obj.roleName.toLowerCase() === 'admin'),
          )
          setTeachersList([...techers])
        })
        .catch(e => {
          message.error(`${Messages.unHandledErrorMsg}`)
          history.replace({pathname: '/', state: {isActive: true}})
        })
  }, [props.studentId, props.categoryId, props.learningTarget])

  const handleSubmit = async e => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        const requestMetadata = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
        fetch(
          `${baseUrl}/LearningTargets/BulkEntry?rosterId=${
            inputs.rosterId
          }&date=${inputs.date}&teacherId=${
            user.role === 'Teacher' ? user.userId : inputs.teacherId
          }&status=${inputs.proficiency}&learningTarget=${
            inputs.learningTarget
          }&categoryId=${inputs.categoryId}`,
          requestMetadata,
        )
          .then(res => res.json())
          .then(data => {
            if (data.statusCode === 200) {
              message.success('Proficiencies updated successfully !!')
              props.form.resetFields()
              props.loadData()
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    })
  }

  const onDateChange = (field, value) => {
    if (value) {
      value = value._d.toISOString()
      setInputs({
        ...inputs,
        [field]: value,
      })
    }
  }

  return (
    <div>
      <Form
        name="basic"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        initialValues={{remember: true}}
        onSubmit={handleSubmit}
        autoComplete="off">
        <Form.Item label="Target Number" name="learningTarget">
          {getFieldDecorator('learningTarget', {
            rules: [
              {
                required: true,
                message: 'Please select Target Number!',
              },
            ],
          })(
            <Select
              initialValue="Select"
              onChange={value => setInputs({...inputs, learningTarget: value})}
              name="learningTarget">
              {learningTargetList &&
                learningTargetList.map((lt, key) => (
                  <Option key={key} value={lt.learningTarget}>
                    {lt.learningTarget}
                  </Option>
                ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label="Target Proficiency" name="proficiency">
          {getFieldDecorator('proficiency', {
            rules: [
              {
                required: true,
                message: 'Please select proficiency!',
              },
            ],
          })(
            <Select
              value="Select"
              onChange={value =>
                setInputs({...inputs, proficiency: value.toString()})
              }
              name="proficiency"
              style={{width: 120}}>
              {proficiencyList &&
                proficiencyList.map((proficiency, key) => (
                  <Option key={key} value={proficiency}>
                    {' '}
                    {proficiency}
                  </Option>
                ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label="Date">
          {getFieldDecorator('date', {
            rules: [
              {
                required: true,
                message: 'Please select  date!',
              },
            ],
          })(
            <DatePicker
              format="MM-DD-YYYY"
              setFieldsValue={inputs.date}
              placeholder="Date"
              onChange={value => onDateChange('date', value)}
              allowClear={autoclearOn}
            />,
          )}
        </Form.Item>

        {user.role.toLowerCase() === 'teacher' ? (
          <Form.Item label="Teacher Name" name="teacherId">
            {getFieldDecorator('teacherId', {
              rules: [],
              initialValue: user.name,
            })(<Input disabled type="text" name="teacherId" />)}
          </Form.Item>
        ) : (
          <Form.Item label="Teacher Name" name="teacherId">
            {getFieldDecorator('teacherId', {
              rules: [
                {
                  required: true,
                  message: 'Please select teacher name!',
                },
              ],
            })(
              <Select
                initialValue="Select"
                onChange={value => setInputs({...inputs, teacherId: value})}
                name="teacherId">
                {teachersList &&
                  teachersList.map((teacher, key) => (
                    <Option key={key} value={teacher.id}>
                      {teacher.firstName} {teacher.lastName}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        )}

        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Form.create()(AddBulkEntry)
